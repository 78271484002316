/* eslint-disable camelcase */
import firebase from 'firebase';
import * as moment from 'moment';
import { env } from '../constants';

export const numberWithCommas = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const convertToCurrency = (x, currencyType = 'USD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyType,
  });

  return formatter.format(x);
};

export const getConvertedAmountForMilestone = ({
  paymentAmount,
  invoiceAmount,
  creditsAmount, // Always in EUR
  conversionRates,
  invoiceCurrency,
  projectCurrency = 'USD',
  type = 'payment',
  hideIfUSD = false,
  showParentheses = false,
}) => {
  let amount = '0.00';

  // If there is a paymeent or creditsAmount, we should use that
  if (paymentAmount || creditsAmount) {
    // If we are looking for total, we should use paymentAmount + creditAmount
    if (type === 'total') {
      amount = convertToCurrency(
        parseFloat(paymentAmount || '0') + parseFloat(creditsAmount || '0'),
        invoiceCurrency || projectCurrency
      );
    }

    if (type === 'credits') {
      amount = convertToCurrency(
        creditsAmount || 0,
        invoiceCurrency || projectCurrency
      );
    }

    // We are looking for payment amount
    if (type === 'payment') {
      amount = convertToCurrency(
        paymentAmount || 0,
        invoiceCurrency || projectCurrency
      );
    }
  } else if (invoiceAmount) {
    amount = convertToCurrency(
      invoiceAmount * (conversionRates.USDEUR || 0),
      projectCurrency
    );
  }

  if (showParentheses) {
    amount = `(${amount})`;
  }

  if (hideIfUSD && projectCurrency !== 'EUR') {
    amount = '';
  }

  return amount;
};

export const getConvertedAmountForLineItem = ({
  amount,
  conversionRates,
  paymentAmount,
  creditsAmount,
  invoiceAmount,
  projectCurrency,
}) => {
  // IF there is payment amount, we should manually calculate it from the payment amount, credits amount, and the invoiceAmount
  // if not we should return the converionRates.USDEUR

  let conversionRate = conversionRates.USDEUR;

  if (paymentAmount || creditsAmount) {
    conversionRate =
      (parseFloat(paymentAmount || '0') + parseFloat(creditsAmount || '0')) /
      invoiceAmount;
  }

  // Make sure to round to 2 decimal places and add the projectCurrenty sign
  // Use converTtoCurrency function
  return `(${convertToCurrency(amount * conversionRate, projectCurrency)})`;
};

export const numToMonth = (n) => {
  switch (n) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    case 11:
      return 'Dec';
    default:
      return '';
  }
};

export const sumCost = (delivs) => {
  let cost = 0;

  for (let i = 0; i < delivs.length; i += 1) {
    cost += delivs[i].cost;
  }

  return cost;
};

export const getBase64 = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const convertDateToUTC = (date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

export const dayJsToTimestamp = (time) => {
  // Convert dayjs to firebase timestamp
  return firebase.firestore.Timestamp.fromDate(time.toDate());
};

export const id = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const isDevEnv = () => {
  return env.env === 'dev';
};

export const isDevServer = () => {
  return env.isDevServer;
};

export const aggregateAmountForStatus = (status, projects, milestones) => {
  const data = {
    aggregateTotalAmount: 0,
    aggregateTotalMilestones: 0,
  };

  projects.forEach(function (project) {
    Object.values(milestones)
      .filter((milestone) => milestone.project_id === project.id)
      .forEach(function (milestone) {
        if (milestone != null && milestone.status == status) {
          data.aggregateTotalAmount += Number(milestone.amount);
          data.aggregateTotalMilestones += 1;
        }
      });
  });
  return data;
};

export const averageOverdueDays = (milestones) => {
  let total = 0;

  milestones.forEach(function (milestone) {
    if (milestone.due_date != null) {
      total += moment(moment(Date())).diff(
        milestone.due_date.toDate(),
        'days',
        true
      );
    }
  });

  return Number(total / milestones.length).toFixed(1);
};

export const currencyFormat = (num, decimals) => {
  return `$${Number(num)
    .toFixed(decimals)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const roundPrice = (num) => Number(Math.round(Number(num) * 100) / 100);

export const isAdmin = (user) => user.acc_type === 2;

export const getCustomerId = (currentUser) =>
  process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
    ? currentUser.customer_id_dev
    : currentUser.customer_id;

export const getEurConversionRate = async () => {
  try {
    // This is a EUR project, so we should be charging in EUR
    // Let's grab the conversion rate first for EUR
    const conversionRateRes = await fetch(
      'https://api.currencylayer.com/live?access_key=b68cf402d91375e304fa668c0083da54&currencies=USD,EUR&format=1'
    );
    const conversionRate = await conversionRateRes.json();

    if (conversionRate && conversionRate.quotes) {
      return conversionRate.quotes.USDEUR;
    }
  } catch (error) {
    console.error('Error fetching currency conversion rate', error);
  }

  return 1;
};

// Function to calculate late fee
const calculateLateFee = (amount, net_due_date) => {
  const currentDate = new Date();
  const dueDate = new Date(
    net_due_date.toDate ? net_due_date.toDate() : net_due_date
  );

  dueDate.setDate(dueDate.getDate() + 3);
  // If current date is after the due date, calculate the late fee
  if (currentDate > dueDate) {
    // Convert the difference between dates from milliseconds to days
    const daysLate = (currentDate - dueDate) / (1000 * 60 * 60 * 24);
    // Calculate the number of 30 day periods late, starting from 1
    const periodsLate = Math.ceil(daysLate / 30);

    // If it is late, calculate the late fee
    if (periodsLate > 0) {
      const lateFee = amount * (1.015 ** periodsLate - 1);
      // Cap the result at 2 decimal places and return
      return Math.round(lateFee * 100) / 100;
    }
  }

  // If not late, return 0
  return 0;
};

// Function that takes in project and the milestone and returns the late fee amount (if any)
export const getLateFeeAmount = (project, milestone) => {
  // If the status is not paid

  // If no due_date, return 0
  if (milestone.due_date == null) {
    return 0;
  }

  if (milestone.amount == null || milestone.amount === 0) {
    return 0;
  }

  if (milestone.status !== 'paid') {
    // If the project has late_fee_enabled as false or null, return 0
    if (!project.late_fee_enabled) {
      return 0;
    }

    // If the project has late_fee_enabled as true, and milestone has late_fee_enabled, lets calculate the late fee
    if (project.late_fee_enabled && milestone.late_fee_enabled) {
      // If there is no net_due_date, add 14 days to the due_date
      const net_due_date = milestone.net_due_date
        ? milestone.net_due_date
        : moment(milestone.due_date.toDate()).add(14, 'days');

      const fee = calculateLateFee(milestone.amount, net_due_date);

      return fee;
    }
  }

  // If the status is paid, we will ignore the project level late_fee_enabled, and use the milestone level late_fee_enabled
  if (milestone.status === 'paid' && milestone.late_fee_enabled) {
    // If late fee exists in the milestone payment data, use that
    if (
      milestone.paymentData &&
      (milestone.paymentData.lateFee || milestone.paymentData.lateFee === 0)
    ) {
      return milestone.paymentData.lateFee;
    }

    // If there is no net_due_date, add 14 days to the due_date
    // const net_due_date = milestone.net_due_date
    //   ? milestone.net_due_date
    //   : moment(milestone.due_date.toDate()).add(14, 'days');
    return 0;
  }

  return 0;
};
